import { LoadingSpinner } from '@headrace/ui';
import { getFromStorage } from '@headrace/utils';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import ViewState from '@/components/Dashboard/ViewState';
import { useEmployee } from '@/lib/EmployeeProvider';

const Home: NextPage = () => {
  const { replace } = useRouter();
  const { employee } = useEmployee();
  const isHeadRaceAI = employee?.employer.isHeadRaceAI;
  useEffect(() => {
    if (isHeadRaceAI) {
      if (employee?.roles && employee.roles.length > 0) {
        // Check if we have a lastRoleIdViewed in local storage
        const lastRoleIdViewed = getFromStorage('lastRoleIdViewed');
        if (lastRoleIdViewed) {
          replace(`/roles/${lastRoleIdViewed}`).catch(null);
          return;
        }
        replace(`/roles/${employee.roles[0].id}`).catch(null);
        return;
      }

      replace('/roles/create').catch(null);
    }
  }, [employee?.roles, isHeadRaceAI, replace]);
  return employee && !isHeadRaceAI ? <ViewState /> : <LoadingSpinner />;
};

export default Home;
