import { LoadingSpinner, UserStats } from '@headrace/ui';
import { pluralize } from '@headrace/utils';
import { ExclamationIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

import { useEmployee } from '@/lib/EmployeeProvider';
import useEmployerDashboardMetrics from '@/lib/hooks/useEmployerDashboardMetrics';

interface EmployerStatsCardProps {
  className?: string;
}

const EmployerStatsCard: VFC<EmployerStatsCardProps> = (props) => {
  const { className } = props;
  const { employee, loading: loadingRecruiter, isDemoAccount } = useEmployee();

  const { roles, candidates } = useEmployerDashboardMetrics();

  if (loadingRecruiter) {
    return (
      <div className={classNames('bg-white shadow rounded-lg p-12', className)}>
        <LoadingSpinner />
      </div>
    );
  }

  if (!employee) {
    return (
      <div
        className={classNames(
          'flex flex-col items-center text-center justify-center bg-white shadow rounded-lg p-12',
          className
        )}
      >
        <ExclamationIcon className="h-12 w-12 text-gray-400" />
        <p className="text-sm text-gray-500">
          No employer information found for the current user. You must complete
          registration process.
        </p>
      </div>
    );
  }

  return (
    <UserStats
      className={className}
      userImageSrc={employee.user.photoUrl}
      userName={`${employee.user.firstName} ${employee.user.lastName}`}
      userSubtitle={employee.employer.company.name}
      stats={[
        {
          statTitle: `active ${pluralize('role', roles.data)}`,
          statValue: roles.data,
          statLabel: `active ${pluralize('role', roles.data)}`,
          loading: roles.loading,
          redirectTo: '/roles',
        },
        {
          statTitle: `${
            candidates.data?.submittedCount || 0
          } candidates to review`,
          statValue: isDemoAccount ? 4 : candidates.data?.submittedCount || 0,
          statLabel: 'candidates to review',
          loading: candidates.loading,
          redirectTo: '/candidates',
        },
        {
          statTitle: `${
            candidates.data?.interviewingCount || 0
          } candidates interviewing`,
          statValue: isDemoAccount
            ? 5
            : candidates.data?.interviewingCount || 0,
          statLabel: 'candidates interviewing',
          loading: candidates.loading,
          redirectTo: '/candidates/interviewing',
        },
        {
          statTitle: `${candidates.data?.hiredCount || 0} candidates hired`,
          statValue: isDemoAccount ? 6 : candidates.data?.hiredCount || 0,
          statLabel: 'candidates hired',
          loading: candidates.loading,
          redirectTo: '/candidates/hired',
        },
      ]}
    />
  );
};

export default EmployerStatsCard;
