import { formatApolloError } from '@headrace/utils';

import {
  useActiveRolesNumberByEmployerQuery,
  useCandidateMetricsByEmployerQuery,
} from '@/graphql/generated';

interface Query {
  error: string | null;
  loading: boolean;
}

interface Metrics extends Query {
  data: number;
}

interface CandidateMetrics extends Query {
  data?: {
    submittedCount: number;
    interviewingCount: number;
    hiredCount: number;
  };
}

interface EmployerDashboardMetrics {
  roles: Metrics;
  candidates: CandidateMetrics;
}

const useEmployerDashboardMetrics = (): EmployerDashboardMetrics => {
  const {
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useActiveRolesNumberByEmployerQuery();

  const {
    data: candidatesData,
    error: candidatesError,
    loading: candidatesLoading,
  } = useCandidateMetricsByEmployerQuery();

  const employerMetrics = {
    roles: {
      data: rolesData?.activeRolesNumberByEmployer || 0,
      error: rolesError ? formatApolloError(rolesError) : null,
      loading: rolesLoading,
    },
    candidates: {
      data: candidatesData?.candidateMetricsByEmployer,
      error: candidatesError ? formatApolloError(candidatesError) : null,
      loading: candidatesLoading,
    },
  };

  return employerMetrics;
};

export default useEmployerDashboardMetrics;
