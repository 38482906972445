import {
  Banner,
  BellAlertIcon,
  OnboardingCard,
  RocketLaunchIcon,
} from '@headrace/ui';
import { pluralize } from '@headrace/utils';
import type { VFC } from 'react';
import { useState } from 'react';

import CreateRoleModal from '@/components/Role/Create/CreateRoleModal';
import { useCandidatesToReviewQuery } from '@/graphql/generated';

import CandidatesReviewCard from './CandidatesReviewCard';
import EmployerStatsCard from './EmployerStatsCard';
import TimeToReview from './TimeToReview';

const ViewState: VFC = () => {
  const [openCreateRole, setOpenCreateRole] = useState(false);
  const { data, loading, error } = useCandidatesToReviewQuery();
  const candidatesExpiringSoon =
    data?.candidatesToReview.filter((candidate) => candidate.isExpiringSoon) ??
    [];
  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
        <EmployerStatsCard className="w-full col-span-full" />
        {candidatesExpiringSoon.length > 0 && (
          <Banner
            className="col-span-full bg-red-500 !p-2"
            icon={<BellAlertIcon className="w-6 h-6" />}
            iconContainerClassName="!bg-red-600"
            text={`${candidatesExpiringSoon.length} ${pluralize(
              'candidate',
              candidatesExpiringSoon.length
            )} expiring soon, any candidates that are not reviewed in 7 days will be automatically passed`}
            textClassName="!font-normal !text-sm"
          />
        )}
        <div className="col-span-1 gap-6 flex flex-col">
          <TimeToReview className="h-fit" />
          <OnboardingCard
            icon={<RocketLaunchIcon className="w-7" />}
            title="Launch a role"
            description="Hiring for other roles? Create a new role on HeadRace, set your price, and start receiving qualified and interested candidates in no time."
            titleAction="Create your role"
            action={(): void => setOpenCreateRole(true)}
            className="bg-headraceGray-700 col-span-1"
            classNameAction="bg-headraceGray-600"
            showCheckIcon={false}
            smallCard
          />
        </div>
        <div className="xl:col-span-2 col-span-1 bg-gray-50 shadow rounded-lg">
          <CandidatesReviewCard
            candidatesData={data?.candidatesToReview}
            className="w-full shadow-none"
            loading={loading}
            error={error}
          />
        </div>
      </div>
      <CreateRoleModal
        open={openCreateRole}
        onClose={(): void => setOpenCreateRole(false)}
      />
    </>
  );
};

export default ViewState;
