import { BeakerIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import type { VFC } from 'react';

import { useTimeToReviewCandidateQuery } from '@/graphql/generated';

interface Props {
  className?: string;
}

const TimeToReview: VFC<Props> = ({ className }) => {
  const { data } = useTimeToReviewCandidateQuery();
  const timeToReview = data?.timeToReviewCandidate;
  return (
    <div
      className={classNames(
        'relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6',
        className
      )}
    >
      <dt>
        <div className="absolute rounded-md bg-headraceYellow-700 p-3">
          <BeakerIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          Time to review
        </p>
      </dt>
      <dd className="ml-16 flex h-[32px] items-baseline pb-6 sm:pb-3">
        <p className="text-2xl font-semibold text-gray-900">{timeToReview}</p>

        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6" />
      </dd>
      <p className="text-sm font-normal text-gray-500 mb-3">
        Recruiters are more likely to engage in searches where the hiring team
        is responsive to candidate submissions and provides actionable feedback
        on candidates. HeadRace recommends that employers target &#60; 2 days
        for review time
      </p>
    </div>
  );
};

export default TimeToReview;
