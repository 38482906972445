import type { ApolloError } from '@apollo/client';
import {
  Button,
  CardWithHeader,
  Checkbox,
  EmptyStateIllustration,
  ErrorMessageBox,
  LinkedinIcon,
  LoadingSpinner,
  PillButton,
} from '@headrace/ui';
import { formatApolloError, isValidHttpUrl } from '@headrace/utils';
import { ChevronRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import {
  differenceInDays,
  formatDistanceToNow,
  isToday,
  isYesterday,
} from 'date-fns';
import { useRouter } from 'next/router';
import type { VFC } from 'react';
import { useEffect, useState } from 'react';

import type { CandidatesToReviewQuery } from '@/graphql/generated';
import { ampli } from '@/lib/ampli';

interface CandidatesReviewCardProps {
  className?: string;
  candidatesData?: CandidatesToReviewQuery['candidatesToReview'];
  loading: boolean;
  error?: ApolloError;
}

const CandidatesReviewCard: VFC<CandidatesReviewCardProps> = (props) => {
  const { push } = useRouter();
  const { className, candidatesData, loading, error } = props;
  const [candidates, setCandidates] = useState<
    CandidatesToReviewQuery['candidatesToReview']
  >([]);

  const [showExpiringSoon, setShowExpiringSoon] = useState(false);

  useEffect(() => {
    const candidatesToReview = candidatesData ?? [];
    if (showExpiringSoon) {
      setCandidates(
        candidatesToReview.filter((candidate) => candidate.isExpiringSoon)
      );
    } else {
      setCandidates(candidatesToReview);
    }
  }, [candidatesData, showExpiringSoon]);

  const dateStatus = (candidateDate: Date): JSX.Element => {
    const date = new Date(candidateDate);
    let label: string;
    let color = 'text-emerald-500';
    if (isToday(date)) {
      label = 'Today';
    } else if (isYesterday(date)) {
      label = 'Yesterday';
    } else {
      const numberOfDays = differenceInDays(new Date(), date);
      color = 'text-yellow-500';
      if (numberOfDays > 3) {
        color = 'text-red-500';
      }
      label = formatDistanceToNow(date, {
        addSuffix: true,
      });
    }

    return <p className={classNames('text-xs leading-5', color)}>{label}</p>;
  };

  const registerClickEvent = (): void => {
    ampli.roleDashboardCandidateClicked({
      app: 'Employer',
    });
  };

  const registerRoleClickEvent = (): void => {
    ampli.roleDashboardRoleClicked({
      app: 'Employer',
    });
  };

  const renderContent = (): JSX.Element => {
    if (loading) {
      return <LoadingSpinner />;
    }
    if (error) {
      return <ErrorMessageBox error={formatApolloError(error)} />;
    }
    if (!candidates.length) {
      let title = 'No more candidates to review!';
      let description =
        'Congratulations, you dont have any other candidates to review, you are doing an awesome job, take a break and have a nice day!';

      if (showExpiringSoon) {
        title = 'No candidates expiring soon!';
        description =
          'As a reminder, candidate submissions expire 7 days after submission. Expirations exist to make sure timely feedback is provided to candidates and recruiters who have expressed interest in your opportunities. Learn more here';
      }
      return (
        <div className="flex items-center justify-center h-full w-full">
          <EmptyStateIllustration
            image="/illustrations/empty-candidates-review.svg"
            title={title}
            className="w-full"
            description={description}
          />
        </div>
      );
    }
    return (
      <ul className="divide-y divide-gray-100 overflow-y-auto lg:max-h-[455px] rounded-b-lg">
        {candidates.map((candidate) => (
          <li key={candidate.id}>
            <button
              type="button"
              aria-hidden
              onClick={async (e): Promise<void> => {
                e.stopPropagation();
                e.preventDefault();
                await push(
                  `/roles/${candidate.searchAgreementRole.role.id}/candidates/${candidate.id}`
                );
                registerClickEvent();
              }}
              className="relative flex py-4 hover:bg-blue-100 px-6 sm:flex-row flex-col gap-y-3 w-full bg-white"
            >
              <div className="flex gap-x-4 pr-6 sm:w-1/3 sm:flex-none">
                <div className="flex-auto">
                  <p className="text-sm font-semibold leading-6 text-headraceBlack-700 text-left">
                    <span className=" " />
                    {candidate.talent.name}{' '}
                    {candidate.talent.linkedIn && (
                      <a
                        href={
                          isValidHttpUrl(candidate.talent.linkedIn)
                            ? candidate.talent.linkedIn
                            : `https://${candidate.talent.linkedIn}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e): void => e.stopPropagation()}
                      >
                        <LinkedinIcon className="w-3.5 h-3.5" />
                      </a>
                    )}
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500 text-left">
                    <a
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      href={`mailto:${candidate.talent.email!}`}
                      className="relative truncate hover:underline"
                      onClick={(e): void => e.stopPropagation()}
                    >
                      {candidate.talent.email}
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex flex-grow items-center justify-between gap-x-4 w-full">
                <div className="">
                  <p className="text-sm leading-6 text-headraceBlack-700">
                    {candidate.searchAgreementRole.role.name}
                  </p>
                  <div className="mt-1 flex items-center gap-x-1.5">
                    {dateStatus(candidate.createdAt)}
                  </div>
                </div>
                <ChevronRightIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <CardWithHeader
      title="Candidate inbox"
      description={`${candidatesData?.length || 0} ${
        candidatesData?.length && candidatesData?.length > 1
          ? 'candidates'
          : 'candidate'
      } pending your review`}
      headerClassName="bg-white rounded-t-lg"
      className={classNames(className, {
        'h-full': !candidates.length,
      })}
      removeChildrenYPadding
      removeChildrenXPadding
      rightContent={
        <div className="flex gap-3">
          <PillButton
            onClick={(): void => {
              setShowExpiringSoon(!showExpiringSoon);
            }}
            className="flex gap-2"
          >
            Expiring soon
            <Checkbox
              name="expiringSoon"
              checked={showExpiringSoon}
              onChange={(): void => {
                setShowExpiringSoon(!showExpiringSoon);
              }}
            />
          </PillButton>
          <Button
            onClick={async (): Promise<void> => {
              registerRoleClickEvent();
              await push('/roles');
            }}
          >
            View Roles
          </Button>
        </div>
      }
    >
      {renderContent()}
    </CardWithHeader>
  );
};

export default CandidatesReviewCard;
